import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { useEffect, useState } from 'react';

// --- Loading components fading elements in order with timeout and states | show: true on load on timeout becomes false | elementsImages: strapi query images from index --- 
const LoadingTobo = (props: {
    show: boolean, 
    elementsImages: any
}) => {

    // --- Getting tobo images from strapi query    
    const loadingImg: any = props?.elementsImages && props.elementsImages.filter((el: any) => (el?.localFile.name as string).includes('loading'))[0];
    const toboTitle: any = props?.elementsImages && props.elementsImages.filter((el: any) => (el?.localFile.name as string).includes('title'))[0];
    const cardinalLogo: any = props?.elementsImages && props.elementsImages.filter((el: any) => (el?.localFile.name as string).includes('cardinal'))[0];

    const loading: IGatsbyImageData = loadingImg && getImage(loadingImg?.localFile);
    const title: IGatsbyImageData = toboTitle && getImage(toboTitle?.localFile);
    const logo: IGatsbyImageData = cardinalLogo && getImage(cardinalLogo?.localFile);
    // ---

    // State for loading components visibility
    const [isLoad, setIsLoad] = useState<boolean>(true);
    const [isLogos, setIsLogos] = useState<boolean>(false);
    // ---

    useEffect(() => {
        // --- Timeout for states 
        setTimeout(() => {
            setIsLoad(false);
            setIsLogos(true);
            setTimeout(() => {
                setIsLogos(false);
            }, 2500)
        }, 2450)
        // --- 

    }, [props?.show]) // Enters hook every time show changes
    // ---

    // --- Loading always off if show is false
    if (!props?.show) {
        return null;
    }
    // ---

    return (
        <div className='loadingTobo-container'>
            
            {/* --- Loading --- */}
            { isLoad ? (
                <div className='relative flex items-center justify-center w-[95%] lg:w-max h-min fadeOut-loading'>
                    <GatsbyImage image={loading} alt={'LOADING IMAGE'}/>
                    {/* Loading animated bar */}
                    <div className='absolute flex justify-end w-full pt-4 pl-2 lg:pt-10'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="842" height="11" viewBox="0 0 842 11" className='loading-anim'>
                            <rect id="Rettangolo_166" data-name="Rettangolo 166" width="842" height="11" fill="#fff"/>
                        </svg>
                    </div>
                </div>
            ) : null }

            {/* --- Logos --- */}
            { isLogos ? (
                <div className='flex flex-row items-center justify-center w-screen h-64 fadeInOut'>
                    <GatsbyImage image={logo} alt={'CARDINAL LOGO'} className='w-1/3 m-4 lg:w-64 lg:m-24'/>
                    <span className='text-xl text-white uppercase scale-x-150 lg:text-4xl'>X</span>
                    <GatsbyImage image={title} alt={'TOBO LOGO'} className='w-1/3 m-4 lg:w-64 lg:m-24'/>
                </div>
            ) : null }

        </div>
    )

}

export default LoadingTobo;